import React, { FC, memo, useCallback, useState } from 'react';
import { useEvents } from '~/shared/hooks/useEvents';
import { useFrame } from '~/shared/utils';
import { useTranslation } from '~/shared/utils/translation';
import FilterRefinementSwitch from '../../FilterRefinementSwitch/FilterRefinementSwitch';
import {
    StyledCheckmark,
    StyledCheckmarkContainer,
    StyledContainer,
    StyledList,
    StyledListItem,
    StyledListItemLogo,
    StyledListTextContainer,
    StyledListTextWrapper,
    StyledListValueWrapper,
    StyledOnlineToggle,
    StyleListInput,
} from './RefinementList.styled';
import { useDebounce } from 'react-use';
import { RefinementListItem } from '~/features/productList/types';
import useOptimizedRefinementList from '~/shared/hooks/useOptimizedRefinementList';
import RefinementEmptyList from '../../FilterList/components/RefinementEmptyList';
import { useCurrentRefinements } from 'react-instantsearch';
import { FilterDropdownExpand } from '../../FilterList/components/FilterDropdownExpand';
import { useStoreList } from '~/features/commerce-api/hooks/useStoreList';
import { useStoreRefinementData } from '~/features/productList/useStoreRefinementData';

interface RefinementListItemsProps {
    items: RefinementListItem[];
    onRefine(value: string): void;
}

const RefinementListItems: FC<RefinementListItemsProps> = ({ items, onRefine }) => (
    <StyledList column>
        {items.map((item) => (
            <StyledListItem
                key={`${item.label}-${item.value}`}
                isRefined={item.isRefined}
                onClick={() => onRefine(item.value)}
                isDisabled={item.count <= 0}
            >
                <StyledListTextContainer>
                    <StyledListItemLogo />
                    <StyledListTextWrapper>{item.label}</StyledListTextWrapper>
                </StyledListTextContainer>

                {item.count > 0 && (
                    <StyledListValueWrapper>
                        {item.count >= 1 ? `(${item.count})` : ''}
                        {item.count <= 0 ? null : (
                            <StyledCheckmarkContainer isRefined={item.isRefined}>
                                {item.isRefined && <StyledCheckmark isRefined={item.isRefined} />}
                            </StyledCheckmarkContainer>
                        )}
                    </StyledListValueWrapper>
                )}
            </StyledListItem>
        ))}
    </StyledList>
);

const MemoizedRefinementListItems = memo(RefinementListItems);

const RefinementListStock: FC = () => {
    const { items: currentRefinements } = useCurrentRefinements();
    const { data: frame } = useFrame();
    const { translate } = useTranslation();
    const { filterEvent } = useEvents(frame);
    const [searchValue, setSearchValue] = useState('');
    const allStoreData = useStoreList();

    const attribute = 'cust_storeNamesWithStock';

    const { refine, searchForItems, limitItems, allItems, isShowMore, setIsShowMore, limit } =
        useOptimizedRefinementList({
            attribute,
            escapeFacetValues: false,
        });

    const data = useStoreRefinementData(limitItems, allStoreData, searchValue);
    const dataAll = useStoreRefinementData(allItems, allStoreData, searchValue);

    const handleRefine = useCallback((value: string) => {
        if (!value) {
            return;
        }

        filterEvent('Filtering', value.toString());
        refine(value);
        setSearchValue('');
    }, []);

    const showMoreFilterCount = Math.max(0, dataAll.length - limit);
    const shouldShowExpandButton = dataAll.length > limit;

    useDebounce(
        () => {
            searchForItems(searchValue ?? '');
            setIsShowMore(false);
        },
        200,
        [searchValue],
    );

    return (
        <>
            <StyledOnlineToggle
                isRefined={currentRefinements.some((item) =>
                    item.attribute.includes('cust_in_stock_web'),
                )}
            >
                <FilterRefinementSwitch
                    attribute="cust_in_stock_web"
                    label={translate('plp.inStock')}
                    specificFilteringType="Online"
                    value={true}
                />
            </StyledOnlineToggle>

            <StyledContainer>
                <StyleListInput
                    isEmpty={limitItems && limitItems.length === 0}
                    placeholder={translate('plp.searchPlaceholder')}
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                />

                <MemoizedRefinementListItems
                    items={isShowMore ? dataAll : data}
                    onRefine={handleRefine}
                />
                {shouldShowExpandButton && (
                    <FilterDropdownExpand
                        onClick={() => setIsShowMore(!isShowMore)}
                        isExpanded={isShowMore}
                        filterCount={showMoreFilterCount ?? 0}
                    />
                )}
                {data && data.length === 0 && <RefinementEmptyList />}
            </StyledContainer>
        </>
    );
};

export default RefinementListStock;
