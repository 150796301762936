import { useCallback, useMemo } from 'react';
import { RefinementListItem } from './types';
import { UseQueryResult } from '@tanstack/react-query';

export function useStoreRefinementData(
    items: RefinementListItem[],
    allStoreData: UseQueryResult<any, Error>,
    searchValue: string,
) {
    const filterStoresBySearch = useCallback(
        (item) => item.value.toLowerCase().includes(searchValue?.toLowerCase() || ''),
        [searchValue],
    );

    return useMemo(() => {
        const itemsFromAlgolia: RefinementListItem[] = Array.isArray(items)
            ? items.map((item) => ({
                  value: item.value ?? '',
                  label: item.label ?? '',
                  count: item.count ?? 0,
                  isRefined: item.isRefined ?? false,
              }))
            : [];

        const listAllStore = Array.isArray(allStoreData?.data) ? allStoreData.data : [];

        const mergedData = [
            ...listAllStore.map((store: { name: string }) => ({
                value: store.name,
                label: store.name,
                count: 0,
                isRefined: false,
            })),
            ...itemsFromAlgolia,
        ];

        const uniqueData = Array.from(
            new Map(mergedData.map((item) => [item.value, item])).values(),
        );

        const filtredItem = uniqueData.filter(filterStoresBySearch);

        const itemsToReturn = searchValue !== '' ? filtredItem : uniqueData;

        return items.length <= 5
            ? itemsToReturn.sort((a, b) => b.count - a.count).slice(0, 5)
            : itemsToReturn.sort((a, b) => b.count - a.count);
    }, [items, allStoreData, searchValue]);
}
