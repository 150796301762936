import { useQuery } from '@tanstack/react-query';
import { useCommerceAPI } from '~/features/commerce-api/contexts';
import { Store, StoreResponse } from '~/lib/data-contract/salesforce';

export const useStoreList = () => {
    const commerceApi = useCommerceAPI();
    const parameters = {
        latitude: Number('56.09021'),
        longitude: Number('8.24463'),
        count: 200,
        start: 0,
    };

    return useQuery<any, Error>({
        queryKey: ['storesList'],
        queryFn: async () => {
            const result = (await commerceApi.shopperStores?.getStoreList({
                parameters,
            })) as StoreResponse;

            if (result?.next) {
                const promises = [];
                const count = result.count;

                for (let start = count; start <= result.total; start += count) {
                    const params = { ...parameters, start };
                    const promise = commerceApi.shopperStores?.getStoreList({
                        parameters: params,
                    });

                    promises.push(promise);
                }

                const storeListResult = (await Promise.all(promises)) as StoreResponse[];
                const storeList = storeListResult.reduce(
                    (acc, store) => [...acc, ...store.data],
                    [] as Store[]
                );

                return [...result.data, ...storeList];
            }

            return result.data;
        },
    });
};
