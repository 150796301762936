import { useState } from 'react';
import {
    AdditionalWidgetProperties,
    useRefinementList,
    UseRefinementListProps,
} from 'react-instantsearch';

const useOptimizedRefinementList = (
    props: UseRefinementListProps,
    additionalWidgetProperties?: AdditionalWidgetProperties,
) => {
    const [isShowMore, setIsShowMore] = useState(false);
    const limit = 5;
    const { items, refine, ...rest } = useRefinementList(
        {
            escapeFacetValues: false,
            limit: 1000,
            ...props,
        },
        additionalWidgetProperties,
    );

    const limitItems = items.length > limit ? items.slice(0, limit) : items;

    return {
        ...rest,
        items: isShowMore ? items : limitItems,
        allItems: items,
        limitItems,
        refine,
        limit,
        isShowMore,
        setIsShowMore,
        filterCount: Math.max(0, items.length - limit),
        showExpandButton: items.length > limit,
    };
};
export default useOptimizedRefinementList;
