import React, { FC, memo, useCallback, useState } from 'react';
import { useEvents } from '~/shared/hooks/useEvents';
import { useFrame } from '~/shared/utils';
import { useTranslation } from '~/shared/utils/translation';
import IRefinementList from './RefinementList.definitions';
import { SwatchColor } from '~/shared/components';
import {
    StyledCheckmark,
    StyledCheckmarkContainer,
    StyledContainer,
    StyledList,
    StyledListItem,
    StyledListTextContainer,
    StyledListTextWrapper,
    StyledListValueWrapper,
    StyleListInput,
} from './RefinementList.styled';
import useColorSwatches from '~/features/productList/hooks/useColorSwatches';
import { useDebounce } from 'react-use';
import useOptimizedRefinementList from '~/shared/hooks/useOptimizedRefinementList';
import { RefinementListItem } from '~/features/productList/types';
import RefinementEmptyList from '../../FilterList/components/RefinementEmptyList';
import { FilterDropdownExpand } from '../../FilterList/components/FilterDropdownExpand';

interface RefinementListItemsProps {
    items: RefinementListItem[];
    shouldShowCount?: boolean;
    onRefine(value: string): void;
}

const RefinementListItems: FC<RefinementListItemsProps> = ({
    items,
    shouldShowCount,
    onRefine,
}) => {
    const { colorSwatches } = useColorSwatches();

    return (
        <StyledList column>
            {items.map(({ value, label, count, isRefined }) => {
                const imgSrc =
                    colorSwatches?.find((swatch) => swatch.key === value)?.imageUrl || null;

                return (
                    <StyledListItem
                        key={`${label}-${value}`}
                        isRefined={isRefined}
                        onClick={() => onRefine(value)}
                    >
                        <StyledListTextContainer>
                            {imgSrc && <SwatchColor src={imgSrc} alt={label} />}

                            <StyledListTextWrapper>{label}</StyledListTextWrapper>
                        </StyledListTextContainer>

                        <StyledListValueWrapper>
                            {shouldShowCount && count ? ` (${count})` : ''}
                            <StyledCheckmarkContainer isRefined={isRefined}>
                                {isRefined && <StyledCheckmark isRefined={isRefined} />}
                            </StyledCheckmarkContainer>
                        </StyledListValueWrapper>
                    </StyledListItem>
                );
            })}
        </StyledList>
    );
};

const MemoizedRefinementListItems = memo(RefinementListItems);

export const RefinementListColor: FC<IRefinementList> = ({ attribute, searchable, showCount }) => {
    const { data: frame } = useFrame();
    const { translate } = useTranslation();
    const { filterEvent } = useEvents(frame);
    const [searchValue, setSearchValue] = useState('');

    const {
        refine,
        searchForItems,
        items,
        isShowMore,
        setIsShowMore,
        showExpandButton,
        filterCount,
    } = useOptimizedRefinementList({
        attribute,
        escapeFacetValues: false,
    });

    const handleRefine = useCallback((value: string) => {
        if (!value) return;

        filterEvent('Filtering', value.toString());

        refine(value);
        setSearchValue('');
    }, []);

    const handleSearch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
    }, []);

    useDebounce(
        () => {
            searchForItems(searchValue ?? '');
            setIsShowMore(false);
        },
        200,
        [searchValue],
    );

    return (
        <StyledContainer>
            {searchable && (
                <StyleListInput
                    isEmpty={items && items.length === 0}
                    placeholder={translate('plp.searchPlaceholder')}
                    value={searchValue}
                    onChange={handleSearch}
                />
            )}

            <MemoizedRefinementListItems
                items={items}
                shouldShowCount={showCount}
                onRefine={handleRefine}
            />

            {showExpandButton && (
                <FilterDropdownExpand
                    onClick={() => setIsShowMore(!isShowMore)}
                    isExpanded={isShowMore}
                    filterCount={filterCount}
                />
            )}
            {items && items.length === 0 && <RefinementEmptyList />}
        </StyledContainer>
    );
};

export default RefinementListColor;
