import React, { FC } from 'react';
import FilterIcon from '~/icons/filter.svg';
import { Button } from '~/shared/components';
import ITrigger from './Trigger.definitions';
import { StyledWrapper } from './Trigger.styled';

const Trigger: FC<ITrigger> = ({ label, handleToggle }) => {
    return (
        <StyledWrapper>
            <Button onClick={handleToggle} size="small" variant="filter">
                <FilterIcon />
                {label}
            </Button>
        </StyledWrapper>
    );
};

export default Trigger;
